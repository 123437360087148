<template>
  <v-card rounded flat>
    <div id="mapContainer" class="basemap"> 
    </div>
  </v-card>
</template>

<style scoped>
.basemap {
  width: 100%;
  height: 418px;
}
</style>

<script>
import mapboxgl from "mapbox-gl";


export default {
  props: ['lat','lon','codregion'],
  name: "RegionMap",
  data() {
    return {
      accessToken: 'pk.eyJ1IjoibGFiZGV2MjIiLCJhIjoiY2tzaHFvamRtMXdwZDJ1b2E2ZHRrMDNvYyJ9.A2-aL3_dJ9BIK26yO0Ahug',
      data_comuna:null,
      
    }
  },
  mounted() {
    if( this.codregion != null ){
      this.crear_mapa_region(this.lat, this.lon, this.codregion)
    }
    
  },
  computed:{
    updateRegion(){
      return this.codregion;
    },
  },
  watch:{
    updateRegion(){
      if( this.codregion != null ){
        this.crear_mapa_region(this.lat, this.lon, this.codregion)
      }
    }
  },
  methods: {

    get_zoom_region(codigo_region){
        
      const zoom_regiones = {
        1: 5.5,
        2:5.5,
        3:5.5,
        4:6,
        5:6.5,
        6:6.5,
        7:6.5,
        8:6.5,
        9:6,
        10:5.5,
        11:5,
        12:5,
        13:7,
        14:6.5,
        15: 6,
        16: 7
      }

      let codigo = parseInt(codigo_region);

      let zoom =  zoom_regiones[codigo];

      return zoom;
    },

    crear_mapa_region(latitud,longitud,codigo_region){
      const coordinates_region = [longitud, latitud];
      const zoom_region = this.get_zoom_region(codigo_region)
      const mapa = this.create_basemap(coordinates_region,zoom_region)
      this.addRegionSource(mapa);
      this.addRegionLayer(codigo_region,mapa);

    },

    addRegionSource(mapa){
       mapa.on('load', () => {
        mapa.addSource('map_regiones', {
          type: 'vector',
          url: 'mapbox://labdev22.azm906hb',
        });
      });
    },

  
    addRegionLayer(codigo_region,mapa){
      mapa.on('load', () => {
       
        const cod_region = this.formatCOD(codigo_region)
        var hoveredStateId = null;
        const id_layer = 'region'+codigo_region;
        mapa.addLayer({
          id:id_layer,
          source: 'map_regiones',
          'source-layer': 'chile_regiones_comunas-6uwztq',
          type: 'fill',
          paint: {
            'fill-color': '#5D6D7E',
            'fill-opacity':[
                  'case',
                  ['boolean', ['feature-state', 'hover'], false],
                  0.8,
                  0.3
                ],
            'fill-outline-color': ' #00194c'
          },
          "filter": ["==", "codigo_region", cod_region]
        });

        mapa.addLayer({
          'id': 'state-borders',
          'type': 'line',
          source: 'map_regiones',
          'source-layer': 'chile_regiones_comunas-6uwztq',
          'layout': {},
          'paint': {
            'line-color': '#627BC1',
            'line-width': 1.2,
            'line-opacity': 0.8
          },
          filter: ['in','codigo_region',cod_region]
        });

        const popup = new mapboxgl.Popup({
          closeButton: false,
          closeOnClick: false,
          className:"dark"
        });

        mapa.on('mousemove',id_layer, function (e) {
          if (e.features.length > 0) {
            mapa.getCanvas().style.cursor = 'pointer';
            let coordinates = e.features[0].geometry.coordinates.slice();
            let coordinates_i = coordinates[0]
            


            const codigo_comuna = e.features[0].properties.codigo_comuna;

            const description = (''+
              '<div id="title" class="black--text subtitle-1">Comuna</div>'+
              '<div class="black--text">CUT: '+codigo_comuna+'</div>'
            );

            while (Math.abs(e.lngLat.lng - coordinates_i[0][0]) > 180) {
              coordinates_i[0][0] += e.lngLat.lng > coordinates_i[0][0] ? 360 : -360;
            }

            console.log(coordinates_i[0])
            popup.setLngLat(coordinates_i[0]).setHTML(description).addTo(mapa);
            if (hoveredStateId) {
             
              mapa.setFeatureState(
                { source: 'map_regiones',sourceLayer: 'chile_regiones_comunas-6uwztq',id: hoveredStateId},
                { hover: false }
              );
            }
            hoveredStateId = e.features[0].id;
            
            mapa.setFeatureState(
              { source: 'map_regiones',sourceLayer: 'chile_regiones_comunas-6uwztq', id: hoveredStateId },
              { hover: true }
            );

            
          }
        });
          
          // When the mouse leaves the state-fill layer, update the feature state of the
          // previously hovered feature.
        mapa.on('mouseleave', id_layer, function () {
          if (hoveredStateId) {
            mapa.getCanvas().style.cursor = '';
            popup.remove();
            mapa.setFeatureState(
              { source: 'map_regiones', sourceLayer: 'chile_regiones_comunas-6uwztq', id: hoveredStateId},
              { hover: false }
            );
          }
          hoveredStateId = null;
        });


      });
    },

    formatCOD(codigo_region){
      let codigo;
      var tipo = String(codigo_region).length;
      if(tipo == 1){
        if(codigo_region == 8){
          codigo = '08';
        }
        else{
          codigo = '0000'+String(codigo_region);
        }
      }
      else if(tipo == 2){
        if(codigo_region == 16){
          codigo = '16';
        }
        else{
          codigo = '000'+String(codigo_region)
        }
      }

      return codigo;
    },

    create_basemap(coordinates,zoom_region){
      mapboxgl.accessToken = this.accessToken;

      let basemap = new mapboxgl.Map({
        container: "mapContainer",
        style: "mapbox://styles/mapbox/light-v10",
        center: coordinates,
        zoom: zoom_region,
      });

      basemap.addControl(new mapboxgl.NavigationControl());
      basemap.addControl(new mapboxgl.FullscreenControl());

      return basemap;
    },



  }

    // watch: {

    //   item(){
    //     this.longitud = this.lon
    //     this.latitud = this.lat
    //     console.log(this.codregion)
    //     var zoom_by_region;
    //     if(this.codregion === 15   || this.codregion === 6 || this.codregion === 5){
    //       zoom_by_region = 6.5;
    //     }
    //     else if(this.codregion === 1){
    //       zoom_by_region = 6;
    //     }
    //     else if(this.codregion === 2 || this.codregion === 3  || this.codregion === 10){
    //       zoom_by_region = 5.5;
    //     }
    //     else if(this.codregion === 11){
    //       zoom_by_region = 5.0;
    //     }
    //     else if(this.codregion  === 12){
    //       zoom_by_region = 4.1;
    //     }
    //     else if(this.codregion === 4){
    //       zoom_by_region = 5.8;
    //     }
    //     else{
    //       zoom_by_region = 6.5;
    //     }
    //     mapboxgl.accessToken = this.accessToken;
    //     var mapa = new mapboxgl.Map({
    //       container: "mapContainer",
    //       style: "mapbox://styles/mapbox/light-v10",
    //       center: [this.longitud,this.latitud],
    //       zoom: zoom_by_region,
    //     });

        
    //     this.crearMapa1(this.codregion,mapa)
        
        
    //   }
    // },
};
</script>