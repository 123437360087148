<template>
	<div v-if="comuna == 0">
		<Region :region="region" :data_region="data_region"/>
	</div>
	<div v-else>
		<Comuna :comuna="comuna" />
	</div>
</template>

<script>
import Region from '../views/Region/Region';
import Comuna from '../views/Comuna/Comuna';

export default {
	name: 'VistaDashboard',
	props: ['region','comuna','data_region','data_comuna'],
	components: {
		Region,
		Comuna
		
	},
	data: () => ({
	}),
	mounted(){
	}

}
</script>
