<template>
	<div class="comuna">
		<!-- <h2>{{comunaDetail.nombre}}</h2>
		<v-divider/>
		<br>
		<v-row>
			<v-col cols="6">
				<v-select
				dense
				v-model="puntoDetail"
				:items="puntos"
				class="mx-4"
				flat
				hide-no-data
				hide-details
				label="Puntos de entrada/salida"
				solo-inverted
				item-text="nombre"
				item-value="id"
				v-on:change="cambiarPunto"
          ></v-select>
			</v-col>
		</v-row>
		<v-row>
			<v-col cols="6">
			<div v-if="datos_cargados">
				<p class="text-center">Ingreso Carga en Toneladas </p>
				<LineChart :data="data_carga" :labels="anios2"/>
			</div>
			</v-col>
			<v-col cols="6">
			<div v-if="datos_cargados2">
				<p class="text-center">Importacion y Exportacion en Kilogramos </p>
				<MultiBarChart :data1="data_kg_importacion" :data2="data_kg_exportacion" :labels="anios"/>
			</div>
			</v-col>
		</v-row> -->
		
	</div>
</template>


<script>
const API_URL = 'https://api.datari.net/'

// eslint-disable-next-line no-unused-vars
import axios from 'axios';
// import Chart from 'chart.js';
// import LineChart from '../components/charts/LineChart-Punto.vue'
// import MultiBarChart from '../components/charts/MultiBarChart.vue'
export default {
	name: 'Comuna',
	components: {
		// LineChart,
		// MultiBarChart
	},
	props: ['comuna'],
	data() {
		return {
			comunaDetail: [],
			puntos: [],
			puntoDetail: '',
			dataImportacion: [],
			dataExportacion: [],
			dataCargaTerrestre: [],
			data_carga: [],
			data_us_importacion: [],
			data_us_exportacion: [],
			data_kg_importacion: [],
			data_kg_exportacion: [],
			anios: [],
			anios2: [],
			datos_cargados: false,
			datos_cargados2: false

		}
	},
	mounted() {

		this.crearData()

	},  

	methods: {
		crearData(){
			if(this.comuna != null){
				axios.get(API_URL+"comunas/"+this.comuna)
				.then(response => {
					this.comunaDetail = response.data
				});
			}



			axios.get(API_URL+"puntos_ingreso_salida/?comuna="+this.comuna)
			.then(response => {
				this.puntos = response.data.results
			});
		},

		cambiarPunto(punto){
			axios.get(API_URL+'importacion_exportacion_aduana/?punto_ingreso_salida='+punto+'&tipo=importacion')
			.then(response => {
				this.dataImportacion = response.data.results
				this.data_us_importacion = this.obtener_data_dolar(this.dataImportacion)
				this.data_kg_importacion = this.obtener_data_peso(this.dataImportacion)
				this.anios = this.asignar_anios(this.dataImportacion)
				if(this.data_kg_importacion.length == 0){
					console.log('no hay nada')
					this.datos_cargados2 = false
				}
				else{
					this.datos_cargados2 = true
				}

				
			})

			axios.get(API_URL+'importacion_exportacion_aduana/?punto_ingreso_salida='+punto+'&tipo=exportacion')
			.then(response => {
				this.dataExportacion = response.data.results
				this.data_us_exportacion = this.obtener_data_dolar(this.dataExportacion)
				this.data_kg_exportacion = this.obtener_data_peso(this.dataExportacion)
				this.anios = this.asignar_anios(this.dataExportacion)
				if(this.data_kg_exportacion.length == 0){
					console.log('no hay nada')
					this.datos_cargados2 = false
				}
				else{
					this.datos_cargados2 = true
				}

			})

			axios.get(API_URL+'transporte_territorial_aduana/?punto_ingreso_salida='+punto+'&tipo=ingreso')
			.then(response => {
				this.dataCargaTerrestre = response.data.results
				this.data_carga = this.obtener_data_carga(this.dataCargaTerrestre)
				this.anios2 = this.asignar_anios(this.dataCargaTerrestre)
				if(this.data_carga.length == 0){
					console.log('no hay nada')
					this.datos_cargados = false
				}
				else{
					this.datos_cargados = true
				}

			})
		},

		obtener_data_dolar(data){
				var d;
				var data_us = [];
				var anios= [];
				
				for (d in data){
					if(data[d].peso == 0 && !anios.includes(data[d].anio)){
						data_us.push(data[d].valor);
						anios.push(data[d].anio);
					}
				}
				return data_us
		},

		obtener_data_peso(data){
				var d;
				var data_peso = [];
				var anios = [];
				for(d in data){
					if(data[d].valor == 0 && anios.includes(data[d].anio)){
						var valor = parseInt(data[d].peso)
						data_peso.push(valor)
					}
					else{
						anios.push(data[d].anio);
					}
				}
				return data_peso
		},
		obtener_data_carga(data){
				var d;
				var data_carga = [];
				for(d in data){
					var valor = parseInt(data[d].carga.toFixed(0))
					if(!data_carga.includes(valor)){
						data_carga.push(valor)
					}
				}
				return data_carga
		},

		asignar_anios(data){
				var d;
				var anios =  []
				for(d in data){
					if(anios.includes(data[d].anio)){
						console.log('ya existe en data')
					}
					else{
						anios.push(data[d].anio);
					}
					
				}
				return anios
		},
		
		


	},
	computed: {
		item(){
			return this.comuna
		}
	},

	watch: {
		item(){
			this.crearData()
		}
	}

};
</script>
