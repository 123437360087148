<template >
	<div id="Region">
    <div id="header">
      <v-app-bar color="light-blue darken-2" class="rounded" flat dark>
        <h2 class="font-weight-medium">{{formatRegion(data_region.nombre)}}</h2>
      </v-app-bar>
    </div>

    <div id="body" style="margin-top:-15px;">
      <v-row>
        <v-col cols=6>
          <v-row>
            <v-col cols=4>
              <v-card flat>
                <v-card-title class="font-weight-medium">
                  <v-icon
                    medium
                    left
                    >
                    mdi-human-male-female
                  </v-icon>
                  <h4 class=" font-weight-medium">Población</h4>  
                </v-card-title>
                <v-card-text class="text-center">
                  <h1 class="font-weight-bold display-1" >{{habitantes}}</h1>
                  <h3 class="font-weight-medium mt-0">Habitantes</h3>
                  <DonutChart :region='region' :p_mujeres='pob_m' :p_hombres='pob_h' class="mt-4"/>
                  
                </v-card-text>
               </v-card>
            </v-col>
            <v-col cols=8>
              <LineChart v-bind:region="region" />
            </v-col>
          </v-row>
          <v-row style="margin-top:-15px;">
            <v-col cols=4>
              <v-card flat>
                <v-card-title class="font-weight-medium">
                  <v-icon
                  medium
                  left
                  >
                  mdi-human-queue
                  </v-icon>
                  Población
                </v-card-title>
                <v-card-subtitle>
                  <h3 class="font-weight-light">Migrante</h3>
                </v-card-subtitle>
                <v-card-text class="mt-0"> 
                  <h1 class="font-weight-bold display-1 " style="display:inline;">{{pob_migrantes}}</h1>
                  <h3 class="font-weight-medium ml-2" style="display:inline;" >Habitantes</h3>
                </v-card-text>
              </v-card>
              <v-card flat class="mt-2">
                <v-card-title class="font-weight-medium">
                  <v-icon
                  medium
                  left
                  >
                  mdi-human-queue
                  </v-icon>
                  Población
                </v-card-title>
                <v-card-subtitle>
                  <h3 class="font-weight-light">Pueblos Originarios</h3>
                </v-card-subtitle>
                <v-card-text class="mt-0">
                  <h1 class="font-weight-bold display-1 " style="display:inline;" >{{pob_pueblos}}</h1>
                  <h3 class="font-weight-medium ml-2 " style="display:inline;">Habitantes</h3>
                </v-card-text>
              </v-card>
              <v-card  class="mt-2" flat>
                <v-card-title class="font-weight-medium">
                  <v-icon
                    medium
                    left
                    >
                    mdi-buffer
                  </v-icon>
                  Población
                </v-card-title>
                <v-card-subtitle>
                  <h3 class="font-weight-light">Densidad</h3>
                </v-card-subtitle>
                <v-card-text class="mt-0">
                   <h1 class="font-weight-bold display-1" style="display:inline;" >{{densidad_pob}}</h1>
                  <h3 class="font-weight-medium ml-2" style="display:inline;">Habs/km2</h3>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols=8>
              <v-card flat rounded height=443>
                <v-card-title>
                  <v-icon
                    medium
                    left
                  >
                  mdi-truck-delivery-outline
                </v-icon>
                Importación y Exportación
                </v-card-title>
                <v-card-text class="text-center">
                  <v-row>
                    <v-col cols=4>
                      <v-col
                        class="mx-auto"
                        cols="12"
                        sm="12"
                      >
                        <v-select
                          v-model="selected_anio_imp_exp"
                          :items="anios_imp_exp"
                          label="Año"
                          dense
                          outlined
                          item-text="text"
                          item-value="value"
                          v-on:change="cambiarAnio_imp_exp"
                        ></v-select>
                      </v-col>
                    </v-col>
                    <v-col cols=8>
                      <v-col
                        cols="12"
                        sm="12"
                      >
                        <v-select
                          v-model="selected_tipo_imp_exp"
                          :items="tipos_imp_exp"
                          label="Tipo"
                          dense
                          outlined
                          item-text="text"
                          item-value="value"
                          v-on:change="cambiarTipo_imp_exp"
                        ></v-select>
                      </v-col>
                    </v-col>
                  </v-row>
                  <!-- <Donut 
                    style="margin-top:-10px;"
                    :data="data_chart"
                    :labels="labels_chart"
                    :colors="colors_chart"
                    :unidad="unidad_chart"
                    :height_chart="260"
                    :width="'100%'"
                  /> -->

                  <ArcPie 
                    :data="data_chart"
                    :labels="labels_chart"
                    :colors="colors_chart"
                    :unidad="unidad_chart"
                    :tipo_data="tipo_data_chart"
                    :height="250"
                  />

                </v-card-text>
              </v-card>
            </v-col>
              
          </v-row>
        </v-col>
        <v-col cols=6>
          <v-row>
            <v-col cols=4>
              <v-card flat>
                <v-card-title class="font-weight-medium">
                  <v-icon
                  medium
                  left
                  >
                  mdi-home-city-outline
                  </v-icon>
                  Viviendas
                </v-card-title>
                <v-card-subtitle>
                  <h3 class="font-weight-light">Cantidad</h3>
                </v-card-subtitle>
                <v-card-text > 
                  <h1 class="font-weight-bold headline " style="display:inline;">{{viviendas}}</h1>
                  <h3 class="font-weight-medium ml-2" style="display:inline;" >Viviendas</h3>
                </v-card-text>
              </v-card>
              <v-card class="mt-2" flat>
                <v-card-title class="font-weight-medium">
                  <v-icon
                  medium
                  left
                  >
                  mdi-home-city-outline
                  </v-icon>
                  Viviendas
                </v-card-title>
                <v-card-subtitle>
                  <h3 class="font-weight-light">Cantidad Hogares</h3>
                </v-card-subtitle>
                <v-card-text > 
                  <h1 class="font-weight-bold headline " style="display:inline;">{{cant_hogares}}</h1>
                  <h3 class="font-weight-medium ml-2" style="display:inline;" >Hogares</h3>
                </v-card-text>
              </v-card>
              <v-card class="mt-2" flat>
                <v-card-title class="font-weight-medium">
                  <v-icon
                  medium
                  left
                  >
                  mdi-home-city-outline
                  </v-icon>
                  Viviendas
                </v-card-title>
                <v-card-subtitle>
                  <h3 class="font-weight-light">Hacinamiento</h3>
                </v-card-subtitle>
                <v-card-text > 
                  <h1 class="font-weight-bold headline" style="display:inline;"> {{porcentaje_hacinamiento}}%</h1>
                  <h3 class="font-weight-medium ml-2" style="display:inline;" >Hacinamiento </h3>
                </v-card-text>
              </v-card>
             
            </v-col>
            <v-col cols=8>
              <Map :lat='lati' :lon='longi' :codregion="numero_region"/>
              
            </v-col>
          </v-row>
          <v-row style="margin-top:-15px;">
            <v-col cols=12>
              <v-card height="380" width="100%" flat >
                <v-card-text >
                  <v-col cols="12" class="mx-auto" style="margin-top:-15px;" >
                    <v-slide-group
                      v-model="anio"
                      show-arrows
                      mandatory
                      center-active
                      v-on:change="cambiarAnio"
                      dense
                      >
                      <v-slide-item
                      v-for="(anio) in anios"
                      :key="anio"
                      v-slot="{ active, toggle }"
                      >
                          <v-btn
                          class="ma-1"
                          :input-value="active"
                          :color="active ? 'blue-grey lighten-2 white--text' : 'grey lighten-2'"
                          depressed
                          
                          @click="toggle"
                          >
                          {{anio}}
                        </v-btn>
                      </v-slide-item>
                    </v-slide-group>
                  </v-col>
                  <StackChart :region="region" :anio="anios[anio]" :height="290"  class="mt-10" />
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>
  </div>
  
</template>

<script>
  const API_URL = 'https://api.datari.net/datari/data/'

  import axios from 'axios';
  import StackChart from '../../components/charts/StackChart.vue'
  import LineChart from '../../components/charts/LineChart.vue'
  import DonutChart from '../../components/charts/pruebaChart.vue'
  import Donut from '../../components/chartsV2/Donut.vue'

  import Map from '../../components/maps/regionMap.vue'
  import Index from '../indexView.vue';
  import ArcPie from '../../components/chartsV2/ArcPie.vue';



  export default {
    components: {
      StackChart,
      LineChart,
      Map,
      DonutChart,
      Index,
      Donut,
      ArcPie
    },
    name: 'Region',
    props: ['region','data_region'],
    data: () => ({
      regionDetail: [],
      id_region: null,
      habitantes: null,
      viviendas: null,
      cant_hogares:null,
      porcentaje_hacinamiento:null,
      lati :'',
      longi:'',
      pob_m: 0,
      pob_h: 0,
      pob_migrantes: null,
      pob_pueblos: null,
      densidad_pob: null,
      anios: [2011,2012,2013,2014,2015,2016,2017,2018,2019,2020,2021],
      anio: 8,
      anios_imp_exp: [2002,2003,2004,2005,2006,2007,2008,2009,2010,2011,2012,2013,2014,2015,2016,2017,2018,2019,2020],
      selected_anio_imp_exp: 0,
      tipos_imp_exp: [
        {text:'Monto en dólares FOB',value: 'monto',unidad:'Dólares FOB'},
        {text: 'Peso en toneladas', value:'peso',unidad:'Toneladas'}
      ],
      selected_tipo_imp_exp:0,
      data_importacion:null,
      data_exportacion:null,
      numero_region: null,
      data_chart:null,
      labels_chart:null,
      colors_chart:null,
      unidad_chart:null,
      tipo_data_chart:null
      }),

    mounted() {
      let last_index = this.anios_imp_exp.length-1
      this.selected_anio_imp_exp = this.anios_imp_exp[last_index];
      this.selected_tipo_imp_exp = this.tipos_imp_exp[0];
      let tipo_imp_exp = this.selected_tipo_imp_exp.value;
      const data_chart = this.crear_data_region_imp_exp(this.region,tipo_imp_exp,this.selected_anio_imp_exp);
     
      this.data_chart = data_chart.series;
      this.labels_chart = data_chart.labels;
      this.colors_chart = data_chart.colors;
      this.unidad_chart = data_chart.tipo_data[0].unidad;
      this.tipo_data_chart = data_chart.tipo_data[0].value;
       

      this.crearData()
      this.anio = this.anios.length-1;     
      
    },
    computed : {
       item(){
        return this.data_region
      }
    }, 

    watch: {
      item(){
        let last_index = this.anios_imp_exp.length-1
        this.selected_anio_imp_exp = this.anios_imp_exp[last_index];
        this.selected_tipo_imp_exp = this.tipos_imp_exp[0];
        let tipo_imp_exp = this.selected_tipo_imp_exp.value;

        const data_chart = this.crear_data_region_imp_exp(this.region,tipo_imp_exp,this.selected_anio_imp_exp);
        this.data_chart = data_chart.series;
        this.labels_chart = data_chart.labels;
        this.colors_chart = data_chart.colors;
        this.unidad_chart = data_chart.tipo_data[0].unidad;
        this.tipo_data_chart = data_chart.tipo_data[0].value;
       


        this.crearData()
        this.anio = this.anios.length-1;
      }
    },

    methods: {

      crear_data_region_imp_exp(region,tipo, anio){
        const url = 'importacion_exportacion_aduana/'
        let series = [];
        let labels = [];
        
        //Importación
        axios.get(API_URL+url+'?region='+region+'&tipo_dato='+tipo+'&anio='+anio+'&tipo=importacion')
        .then(response => {
          const data = response.data;
          const data_region = data.data_region;
          const total_region = data_region.total_anual;
          series.push(total_region)
          labels.push('Importación')
          this.data_importacion = total_region;
        });

        axios.get(API_URL+url+'?region='+region+'&tipo_dato='+tipo+'&anio='+anio+'&tipo=exportacion')
        .then(response => {
          const data = response.data;
          const data_region = data.data_region;
          const total_region = data_region.total_anual;
          series.push(total_region)
          labels.push('Exportación')

          this.data_exportacion = total_region;
        });

        let data_chart = {
          series:series,
          labels: labels,
          colors: ['#0069c0','#001970'],
          tipo_data: this.tipos_imp_exp.filter( e => e.value == tipo )
        }
        return data_chart

      },


      crearData(){
        
        if(this.data_region.datos_censo != undefined){
          this.numero_region = this.data_region.numero_region;
          const data_censo = this.data_region.datos_censo[0]
          this.habitantes = this.formatCL(data_censo.cantidad_habitantes)
          this.viviendas = this.formatCL(this.data_region.datos_censo[0].cantidad_viviendas)
          this.lati = this.data_region.lat;
          this.longi = this.data_region.lon;
          this.pob_h = this.data_region.datos_censo[0].cantidad_hombres;
          this.pob_m = this.data_region.datos_censo[0].cantidad_mujeres;
          this.densidad_pob = this.data_region.datos_censo[0].densidad_poblacion;
          this.porcentaje_hacinamiento = this.data_region.datos_censo[0].porcentaje_hacinamiento;
          this.cant_hogares = this.formatCL(this.data_region.datos_censo[0].cantidad_hogares);
          this.densidad_pob = String(this.densidad_pob).replace('.',',');
          this.pob_migrantes = this.formatCL(this.data_region.datos_censo[0].poblacion_migrante);
          this.pob_pueblos = this.formatCL(this.data_region.datos_censo[0].poblacion_pueblos);
        }
        

      
      },

      cambiarAnio(anio){
        this.anio = anio;
      },

      cambiarAnio_imp_exp(anio){
        this.selected_anio_imp_exp = anio;
      

        const data_chart = this.crear_data_region_imp_exp(this.region,this.selected_tipo_imp_exp,anio);
        this.data_chart = data_chart.series;
        this.labels_chart = data_chart.labels;
        this.colors_chart = data_chart.colors;
        this.unidad_chart = data_chart.tipo_data[0].unidad;
        this.tipo_data_chart = data_chart.tipo_data[0].value;
       


      },

      cambiarTipo_imp_exp(tipo){
        this.crear_data_region_imp_exp(this.region,tipo,this.selected_anio_imp_exp);

        const data_chart = this.crear_data_region_imp_exp(this.region,tipo,this.selected_anio_imp_exp);
        this.data_chart = data_chart.series;
        this.labels_chart = data_chart.labels;
        this.colors_chart = data_chart.colors;
        this.unidad_chart = data_chart.tipo_data[0].unidad;
        this.tipo_data_chart = data_chart.tipo_data[0].value;
       

      },


      sleep(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
      },

      formatRegion(region){
        let tipo;
        let final;
        if(region === 'Metropolitana'){
          tipo = 'Región ';
        }else if(region === 'Biobío' || region === 'Maule' ){
          tipo = 'Región del '
        }else{
          tipo = 'Región de '
        }
        final = tipo+region;
        return final;

      },

      formatCL(val){
        var valor = val.toLocaleString();
        var largo_string = valor.length
        var i;
        var stringFormat = ''; 
        for(i of valor){
          if( i === '.' ){
            i = ',';
            stringFormat+=i;
          }
          else if(i ===','){
            i = '.';
            stringFormat+=i;
          }
          else{
            stringFormat+=i;
          }
        }
        return stringFormat;
      },
    },


  }
</script>