<template>
  <div id="Chart">
    <apexchart type="pie" width="270" ref="chart1" :options="chartOptions" :series="series" class="chart-wrapper"></apexchart>
  </div>
</template>

<style scoped>
div.chart-wrapper {
    display: flex;
    align-items: center;
    justify-content: center
}
</style>

<script>
export default {
  name: 'DonutChart',
  props: ['p_hombres','p_mujeres','region'],
  data() {
    return {
      series: [44, 55],
      chartOptions: {
        chart: {
          width: 380,
          type: 'pie',
        },
        labels: ['Hombres', 'Mujeres'],
        colors: ['#3498DB','#EC7063'],
        dataLabels: {
          enabled: true,
          offsetY: 300,
          textAnchor: 'middle',
          style: {
            fontSize: '14px',
            fontFamily: 'Helvetica, Arial, sans-serif',
            fontWeight: 'bold'
          },
          formatter: function (val, opts) {
              return val.toFixed(1)+'%'
          },
        },
        plotOptions: {
              pie: {
                dataLabels: {
                  offset: -20
                }
              }
            },
        legend: {
          position: 'bottom'
        },
        responsive: [{
          breakpoint: 480,
          options: {
            chart: {
              width: 200
            },
            legend: {
              position: 'top'
            }
          }
        }]
      },
    }
  },
  mounted(){
    this.$refs.chart1.updateOptions({
      series: [this.p_hombres,this.p_mujeres],
    })
  },

  computed: {
    item(){
      return this.p_mujeres, this.p_hombres
    }
  },
  watch: {
    item(){

      this.$refs.chart1.updateOptions({
        series: [this.p_hombres,this.p_mujeres],
      })
    }
  }

}
</script>