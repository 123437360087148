<template>
	<div id="BarChart">
		<v-card flat class="text-center mx-auto" height="355" >
			<v-card-title >
				<v-icon
					medium
					left
				>
				mdi-trending-up
			</v-icon>
			
				<h4 class=" font-weight-medium">PIB Anual</h4>  
			</v-card-title>
			<v-card-text class=" font-weight-medium">
				<apexchart style="margin-top: -30px" width="95%"  height="300" ref="chart1" type="line" :options="options" :series="series" class="chart-wrapper"></apexchart>
			</v-card-text>
		</v-card>
	</div>
</template>

<!-- Centrar Chart --->
<style scoped>
div.chart-wrapper {
	display: flex;
	align-items: center;
	justify-content: center
}
</style>


<script>
	const API_URL = 'https://api.datari.net/datari/data/'
	import axios from 'axios';



	export default {
		name: 'AreaChart',
		props: ['region',],
		data() {
			return {
				options: {
					// title: {
					// 	text: 'PIB Anual Regional (en Miles de Millones de pesos)',
					// 	align: 'left'
					// },
					chart: {
						id: 'ChartBart',
						toolbar:{
							show:false
						}
					},
					colors: [
					'#3B93A5',
					'#F7B844',
					'#ADD8C7',

					],
					xaxis: {
						type: "categories",
						categories: [0],
						style: {
							colors: '#fff',
							fontSize: '12px'
						},

					},
					yaxis:{
						show:true,
						forceNiceScale: true,
						axisBorder: {
							show:true,
							color: '#78909C',
							offsetX: -25,
							offsetY: 0
						},
						labels: {
							show: false,
							offsetX: -15,
							formatter: function (val) {
								return '$'+val.toLocaleString().replace(',','.')+ ' Miles de millones de pesos'
							}
						}
					},
					dataLabels: {
						enabled: true,
						formatter: function (val) {
							
							return '$'+val.toLocaleString().replace(',','.')
						},
						
					},
					stroke: {
						curve: 'straight',
					}
				},
				markers: {
					size: 1,
				},
				colors:[
				'#2E93FA'
				],
				series: [
				{
					name: '',
					type: 'line',
					data: []
				}

				],
				pib_anual: [],
				anio: '',
				anios: [],
				valores: []
				
			}
		},
		mounted() {

			this.crearData()

		},
		computed:{
			item(){
				return this.region
					
			},
		},

		watch: {
			item(){
				this.crearData()
			}
		},
		methods: {

			organizar_pib(data){
				var d;
				
				if (this.valores.length > 0){
					this.valores = []
					this.options.xaxis.categories = [0]
				}
				
				for(d in data){
					this.options.xaxis.categories.push(data[d].anio)
					var valor = data[d].valor_pib.toString()
					if(valor.includes('.')){
						var especifico_valor = valor.split('.');
						if(especifico_valor[1].length < 3){
							valor+= '0';
							valor = valor.replace('.','');
							this.valores.push(parseInt(valor))
						}else {
							valor = valor.replace('.','')
							
							this.valores.push(parseInt(valor))
						}
					}
					else if (valor.length == 1){
						valor += '000';
						
						this.valores.push(parseInt(valor))
					}
					else{
						
						this.valores.push(parseInt(valor))
					}
				}
				this.options.xaxis.categories.splice(0,1)
				
				
			},

			crearData(){
				axios.get(API_URL + "pib_anual/?region="+this.region)
				.then(response =>{
				this.pib_anual = response.data.results
				this.organizar_pib(this.pib_anual)
				this.$refs.chart1.updateSeries([
				{
					name: 'PIB',
					data: this.valores
				},
				
				]);

				})
			}

		}
	};
</script>
